@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '~@johnlewispartnership/wtr-ingredients/dist/foundations/colours';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';
@import '../../components/wdx/mixins/typography';

$delete-button-size: $ingredients-unit * 8;

.wrapper {
  display: block;
  width: 100%;
  margin: ($ingredients-gutter * .75) 0 0;
  padding: 0;
  text-align: center;
  position: relative;
  color: $colour-earl-grey;

  @include media-breakpoint-up('md') {
    margin: ($ingredients-gutter * .25) 0 0;
  }

  &.left {
    text-align: left;
  }
}

$overall-icon-spacing: $ingredients-unit * 22;

.editable {
  display: flex;

  .title {
    max-width: calc(100% - #{$overall-icon-spacing});
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
}

.title {
  @include title-h2;
  color: $text-color;
  display: inline-block;
  letter-spacing: .2em;
  margin: ($ingredients-unit * 2) 0;
  padding: 0;
}

.capitalise {
  text-transform: capitalize;
}

.hidden {
  margin: 0;
  visibility: hidden;
}

.invert {
  color: $colour-white;
  font-weight: 400;
  text-transform: uppercase;
}

.underline {
  &::after {
    border-bottom: {
      width: 2px;
      style: solid;
      color: $colour-primary-grey;
    }
    content: ' ';
    display: block;
    margin: 11px auto ($ingredients-unit * 3);
    width: 70px;

    .hasSubcategories & {
      border-bottom: 0;
    }
  }

  &.invert {
    &::after {
      border-bottom-color: $colour-white;
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.hasSubTitle {
  margin-bottom: 0;
}

%highlight {
  font-weight: 400;
  margin-left: .2em;
  text-transform: uppercase;
}

.highlight {
  @extend %highlight;
}

.offerHighlight {
  @extend %highlight;
  color: $colour-offer-red;
}

.editButton {
  margin-top: $ingredients-unit * -1;
  padding: ($ingredients-gutter * .9) 0 0;

  button {
    background-color: $colour-scallop-grey;
  }

  @include media-breakpoint-up('sm') {
    margin-top: $ingredients-unit * -.5;
    padding-top: $ingredients-gutter;
  }
}

.editButtonCentered {
  display: flex;
  align-items: center;

  button {
    background-color: $colour-scallop-grey;
  }
  
  @include media-breakpoint-up('sm') {
    margin-top: 4px;
  }
}

%icon {
  height: $ingredients-unit * 8;
  margin: {
    bottom: 0;
    left: auto;
    right: auto;
    top: -6px;
  }

  @include media-breakpoint-up('md') {
    height: $ingredients-unit * 9;
  }
}

.favourites,
.instoreFavourites,
.forYou {
  @extend %icon;
  margin-right: ($ingredients-unit * 3);
  vertical-align: middle;
}

.favourites,
.forYou {
  path {
    fill: $colour-success-green;
  }
}

.editFormWrapper {
  width: $ingredients-unit * 54;

  @include media-breakpoint-up('sm') {
    width: $ingredients-unit * 112;
  }
}

.mywaitrose {
  color: $colour-corporate-lime-green;
}

.deleteButton {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  width: $delete-button-size;

  button {
    background-color: $colour-scallop-grey;
  }

  @include media-breakpoint-up('sm') {
    margin-top: 4px;
  }
}

.noMargin {
  margin: 0;
}

.h1noMargin {
  margin: 4px 0;
}