@import 'styles/settings/_variables.scss';

.editButton {
  svg {
    opacity: .6;
    margin-left: 6px;

    &:hover {
      opacity: 1;
      transition: opacity .2s linear;
    }
  }
}
