@import '../../../styles/settings/variables';
@import '../../wdx/mixins/typography';

$modal-button-spacing: $grid-vertical-gutter * .5;

.listName {
  @include title-modal;
  display: flex;
  flex: {
    direction: row;
    wrap: nowrap;
  }
  justify-content: center;
  width: 100%;
}
