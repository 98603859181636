.delButton {
  svg {
    opacity: .6;

    &:hover {
      opacity: 1;
      transition: opacity .2s linear;
    }
  }
}
